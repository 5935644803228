import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import "../styles/general-styles.css"

export default function Footer() {
  return (
    <div>
      <Container fluid="xxl">
        <div className="footer">
          <div className="footer-roc">
            AZ ROC Lic #188403, #188404, #188405, #253276 NV ROC Lic #0052410,
            #0052411 ($50,000 Bond Limit in NV)
          </div>
          <div className="desktop-component">
            <br />
            <Row>
              <Col>
                <p>
                  <Link to="/" className="footer-link">
                    Home
                  </Link>
                </p>
                <br />
                <p>
                  <Link to="/terms-of-use" className="footer-link">
                    Terms of Use
                  </Link>
                </p>
                <br />
                <p>
                  <Link to="/privacy-policy" className="footer-link">
                    Privacy Policy
                  </Link>
                </p>
                <br />
                <p>
                  <a href="tel:+8003666862" className="footer-link">
                    <FontAwesomeIcon icon={faPhoneAlt} size="1x" /> 800-366-6862
                  </a>
                </p>
                <br />
                <p>
                  <a
                    href="mailto:info@sunxtreme.com?subject=Mail from Our Site"
                    className="footer-link"
                  >
                    <FontAwesomeIcon icon={faEnvelope} size="1x" />{" "}
                    info@sunxtreme.com
                  </a>
                </p>
                <a href="#back-to-top">
                  <StaticImage
                    src="../images/sunxtreme-logo.png"
                    className="sunxtreme-footer-logo"
                    alt="SunXtreme logo"
                  />
                </a>
              </Col>
              <Col>
                <div className="footer-location-title">Phoenix</div>
                <div className="footer-location-number">
                  <a href="tel:+6024547000" className="footer-location-link">
                    602-454-7000
                  </a>
                </div>
                <br />
                <div className="footer-location-title">Las Vegas</div>
                <div className="footer-location-number">
                  <a href="tel:+7024354820" className="footer-location-link">
                    702-435-4820
                  </a>
                </div>
                <br />
                <div className="footer-location-title">Tucson</div>
                <div className="footer-location-number">
                  <a href="tel:+5207709902" className="footer-location-link">
                    520-770-9902
                  </a>
                </div>
                <br />
                <div className="footer-location-title">Yuma</div>
                <div className="footer-location-number">
                  <a href="tel:+9287263874" className="footer-location-link">
                    928-726-3874
                  </a>
                </div>
              </Col>
            </Row>
          </div>

          {/* FOOTER FOR MOBILE */}

          <div className="mobile-component">
            <div className="footer-location-title">Phoenix</div>
            <div className="footer-location-number">
              <a href="tel:+6024547000" className="footer-location-link">
                602-454-7000
              </a>
            </div>
            <div className="footer-location-title">Las Vegas</div>
            <div className="footer-location-number">
              <a href="tel:+7024354820" className="footer-location-link">
                702-435-4820
              </a>
            </div>
            <div className="footer-location-title">Tucson</div>
            <div className="footer-location-number">
              <a href="tel:+5207709902" className="footer-location-link">
                520-770-9902
              </a>
            </div>
            <div className="footer-location-title">Yuma</div>
            <div className="footer-location-number">
              <a href="tel:+9287263874" className="footer-location-link">
                928-726-3874
              </a>
            </div>
            <br />
            <p>
              <Link to="/" className="footer-link">
                Home
              </Link>
            </p>
            <br />
            <p>
              <Link to="/terms-of-use" className="footer-link">
                Terms of Use
              </Link>
            </p>
            <br />
            <p>
              <Link to="/privacy-policy" className="footer-link">
                Privacy Policy
              </Link>
            </p>
            <br />
            <p>
              <a href="tel:+8003666862" className="footer-link">
                <FontAwesomeIcon icon={faPhoneAlt} size="1x" /> 800-366-6862
              </a>
            </p>
            <br />
            <p>
              <a
                href="mailto:info@sunxtreme.com?subject=Mail from Our Site"
                className="footer-link"
              >
                <FontAwesomeIcon icon={faEnvelope} size="1x" />{" "}
                info@sunxtreme.com
              </a>
            </p>
            <a href="#back-to-top">
              <StaticImage
                src="../images/sunxtreme-logo.png"
                className="sunxtreme-footer-logo"
                alt="SunXtreme logo"
              />
            </a>
          </div>
          <p className="footer-text">
            © {new Date().getFullYear()} Green Energy, Inc. DBA Sunsplash Solar
          </p>
        </div>
      </Container>
    </div>
  )
}
