import React from "react"
import { Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPhoneAlt,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons"
import "../styles/general-styles.css"

export default function NavBar() {
  return (
    <div className="navbar-background" id="back-to-top">
      <div className="desktop-component">
        <Row noGutters="true">
          <Col>
            <Row noGutters="true">
              <Col>
                <div className="navbar-top-link">
                  <a href="tel:+8003666862" className="navbar-phone-number">
                    <FontAwesomeIcon icon={faPhoneAlt} size="1x" /> 800-366-6862
                  </a>
                </div>
              </Col>
              <Col>
                <Link to="/">
                  <StaticImage
                    src="../images/sunxtreme-logo.png"
                    className="sunxtreme-logo"
                    alt="SunXtreme logo"
                  />
                </Link>
              </Col>
              <Col>
                <div className="navbar-top-link">
                  <a className="free-quote-button" href="/#free-estimate">
                    Get a Free Quote{" "}
                    <FontAwesomeIcon icon={faArrowCircleRight} size="1x" />
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="mobile-component">
        <Link to="/">
          <StaticImage
            src="../images/sunxtreme-logo.png"
            className="sunxtreme-logo"
            alt="SunXtreme logo"
          />
        </Link>
        <Row noGutters="true">
          <Col>
            <div className="navbar-top-link">
              <a href="tel:+8003666862" className="navbar-phone-number">
                <FontAwesomeIcon icon={faPhoneAlt} size="1x" /> 800-366-6862
              </a>
            </div>
          </Col>
          <Col>
            <div className="navbar-top-link">
              <a className="free-quote-button" href="/#free-estimate">
                Free Estimate
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
