import React from "react"
import NavBar from "./navbar"
import Footer from "./footer"
import Wrapper from "./wrapper"
import "../styles/general-styles.css"

export default function Layout(props) {
  return (
    <div className="zen-font-type">
      <NavBar />
      <Wrapper>
        <main className="background-radius-effect">{props.children}</main>
      </Wrapper>
      <Footer/>
    </div>
  )
}
